<template>
  <v-container>
    <v-card class="mb-12">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              disabled
              dense
              hide-details="auto"
              outlined
              :label="$t('hub_*')"
              v-model="inventory.hub_id"
              :items="listHubs"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              disabled
              dense
              outlined
              v-model="inventory.type"
              :items="invTypesList"
              item-value="id"
              item-text="name"
              :label="$t('inventory_type')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              disabled
              dense
              outlined
              v-model="inventory.category_parent_id"
              :items="rootCategories"
              item-value="id"
              item-text="name"
              :label="$t('category_*')"
              :loading="isLoadingCategories"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              disabled
              dense
              outlined
              v-model="inventory.category_id"
              :items="leafCategories"
              item-value="id"
              item-text="name"
              :label="$t('sub_category_*')"
              :loading="isLoadingCategories"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              disabled
              dense
              outlined
              :label="$t('tracking_number')"
              v-model="inventory.tracking_number"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto">
      <v-data-table
        :headers="headersInventory"
        :items="listInventoryProducts"
        :loading="isLoadingProducts"
        :server-items-length="inventoryProductsMeta.totalItems"
        :options.sync="inventory_products_options"
        :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.image"
            :src="item.image.url"
            max-height="50"
            max-width="50"
          />
          <v-img
            v-else
            src="@/assets/product.png"
            max-height="50"
            max-width="50"
          />
        </template>
      </v-data-table>
    </v-card>
    <div class="mt-10">
      <v-btn color="primary" @click="returnToList()"> Return </v-btn>
    </div>
  </v-container>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],

  mounted() {},

  async created() {
    await this.$store.dispatch("inventories/closeForm");
    await this.$store.dispatch("inventories/clearState");
    await this.$store.dispatch("products/closeForm");
    await this.$store.dispatch("hubs/list");
    await this.$store.dispatch("categories/listRoots");
    await this.$store.dispatch("inventories/show", {
      id: this.$route.params.id,
    });
    await this.getInventoryProducts();
  },

  computed: {
    ...mapGetters({
      listHubs: "hubs/activeHubs",
      inventory: "inventories/getInventory",
      rootCategories: "categories/listRoots",
      leafCategories: "categories/listLeafs",

      listInventoryProducts: "inventories/listProducts",
      inventoryProductsMeta: "inventories/productsMeta",
    }),

    category_parent_id() {
      return this.inventory.category_parent_id;
    },
    inventory_id() {
      return this.inventory.id;
    },
  },

  watch: {
    category_parent_id: function (id) {
      this.$store.dispatch("categories/listLeafs", { parent_id: id });
    },

    inventory_products_options: {
      async handler() {
        this.getInventoryProducts();
      },
      deep: true,
    },
  },

  methods: {
    async getInventoryProducts() {
      if (this.inventory_id && this.steps == 1) {
        this.is_loading_product = true;

        await this.$store
          .dispatch("inventories/listProducts", {
            inventory_id: this.inventory_id,
            ...this.inventory_products_options,
          })
          .then(() => {
            this.is_loading_product = false;
          });
      }
    },
    returnToList() {
      this.$router.push({ name: "inventories.index" });
    },
  },

  data() {
    return {
      steps: 1,
      is_loading_product: false,
      isLoadingCategories: false,
      is_valid: false,
      inventory_products_options: { itemsPerPage: 30 },
      all_products_options: { itemsPerPage: 30 },
      tabs: true,
      is_saving_inventory: false,
      invTypesList: [
        { id: "global", name: "Inventaire Globale" },
        { id: "periodic", name: "Inventaire Tournant" },
      ],

      headersInventory: [
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("code"),
          align: "start",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },

        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("physical_qte"),
          value: "physical_qte",
        },
        {
          text: this.$t("theoretical_qte"),
          value: "theoretical_qte",
        },
      ],
    };
  },
};
</script>